<template>
  <el-form ref="form" :model="form" :rules="rules" size="small" label-position="top">
    <el-form-item label="名称" prop="name">
      <el-input v-model="form.name"></el-input>
      <span class="help">用户组名称，必填项，允许小写字母/数字/短横线组成</span>
    </el-form-item>

    <el-form-item label="描述" prop="description">
      <el-input v-model="form.description"></el-input>
    </el-form-item>

    <el-form-item label="添加用户">
      <el-select v-model="groupUserBindings" filterable multiple style="width: 100%" v-if="userList.length > 0">
        <el-option v-for="item in userList" :key="item.uuid" :label="item.name" :value="item.uuid">
          <div>
            <div class="role-title">{{ item.name }}</div>
            <div class="role-desc">{{ item.email }}</div>
          </div>
        </el-option>
      </el-select>

      <span class="help" v-else>
        企业下暂无用户，您可以不绑定用户，或立即
        <a href="javascript:;" @click="$router.push('/admin/user/add')"> [创建用户]</a>
      </span>
    </el-form-item>

    <el-form-item label="设置用户组权限">
      <el-table :data="providers" v-if="providers.length > 0">
        <el-table-column label="#" width="150">
          <template slot-scope="scope">
            <img :src="aws" v-if="scope.row.kind == 'ProviderAWS'" />
            <img :src="aliyun" v-if="scope.row.kind == 'ProviderALiCloud'" />
            <img :src="gcp" v-if="scope.row.kind == 'ProviderGCP'" />
            <img :src="onpremise" v-if="scope.row.kind == 'ProviderKubeconfig'" />
            <img :src="tencentcloud" v-if="scope.row.kind == 'ProviderTencent'" />
            <img :src="huaweicloud" v-if="scope.row.kind == 'ProviderHuawei'" />
          </template>
        </el-table-column>

        <el-table-column :label="$t('Cluster')" prop="cluster">
          <template slot-scope="scope">
            <div class="title">{{ scope.row.cluster }}</div>
            <div class="desc">{{ scope.row.uuid }}</div>
          </template>
        </el-table-column>

        <el-table-column :label="$t('role')">
          <template slot-scope="scope">
            <el-select size="small" filterable v-model="groupUserRoleBindings[scope.row.uuid]">
              <el-option v-for="item in roleList" :key="item.uuid" :label="item.name" :value="item.uuid">
                <div>
                  <div class="role-title">角色名：{{ item.name }}</div>
                  <div class="role-desc">{{ item.description }}</div>
                </div>
              </el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column label="Effect">
          <template slot-scope="scope">
            <el-radio-group v-model="effectBindings[scope.row.uuid]">
              <el-radio-button label="Allow">允许访问</el-radio-button>
              <el-radio-button label="Deny">禁止访问</el-radio-button>
            </el-radio-group>
          </template>
        </el-table-column>
      </el-table>

      <span class="help" v-else>
        企业下暂无集群，请立即
        <a href="javascript:;" @click="$router.push('/admin/cluster/add')">[创建集群]</a>
      </span>
    </el-form-item>

    <slot name="button"></slot>
  </el-form>
</template>

<script>
import provider from "@/mixins/provider";
import { providers } from "api/app";
import { roleList } from "api/role";
import { userList } from "api/user";

export default {
  props: {
    form: Object,
    create: Boolean
  },

  mixins: [provider],
  data() {
    return {
      rules: {
        name: [
          { required: true, message: this.$t("rules.require"), trigger: "blur" },
          {
            pattern: /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/,
            message: this.$t("rules.pattern"),
            trigger: "blur"
          }
        ],

        description: { required: true, message: this.$t("rules.require"), trigger: "blur" }
      },

      providers: [],
      roleList: [],
      userList: [],
      groupUserRoleBindings: {},
      effectBindings: {},
      groupUserBindings: []
    };
  },

  methods: {
    getProviders() {
      providers(this.organization).then(response => {
        if (response.code === 0) {
          this.providers = response.data.items;

          if (this.create)
            this.providers.forEach(item => {
              this.$set(this.effectBindings, item.uuid, "Allow");
            });
        }
      });
    },

    getRoleList() {
      roleList(this.organization).then(response => {
        if (response.code === 0) {
          this.roleList = response.data.items;
        }
      });
    },

    getUserList() {
      userList(this.organization).then(response => {
        if (response.code === 0) {
          this.userList = response.data.items;
        }
      });
    }
  },

  mounted() {
    this.getProviders();
    this.getRoleList();
    this.getUserList();
  },

  computed: {
    organization() {
      return this.$store.state.app.organization;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.el-select-dropdown__item {
  min-height: 60px;
}

.role-title {
  font-size: 12px;
  color: $color-main;
  font-weight: 600;
}

.role-desc {
  font-size: 12px;
  color: $color-sub;
  line-height: 1;
}
</style>
